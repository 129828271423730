/* You can add global styles to this file, and also import other style files */
// html, body { height: 100%; }
// body { margin: 0; font-family: "Montserrat", sans-serif; }

// @import '~@angular/material/theming';

// $custom-typography: mat-typography-config($font-family: '"Montserrat", WorkSans;');
// @include mat-core($custom-typography);


/**** ESTILOS PROYECTO ****/
// Colores blobales del proyecto
:root {
  font-family: 'Work Sans', sans-serif;

  

  h1,h2,h3,h4,h5,h6{
    color: #004884;
    font-weight: bold;
  }
  h1{
    font-size: 32px;
    line-height: 3rem;
    
  }
  h2{
    font-size: 32px;
    line-height: 2.875rem;
    
  }
  h3 {
    font-size: 24px;
    line-height: 2.25rem;
  }
  h4{
    font-size: 20px;
    line-height: 1.875rem;
    font: 100 25px 0;
  }
  h5{
    font-size: 18px;
    line-height: 1.688rem;
  }
  p{
    color: #4b4b4b;
    line-height: 1.5rem;
    font-size: 16px;
  }
  a{
    color: #3366CC;
    line-height: 1.375rem;
    font-size: 15px;
  }

  button{
    color: #FFFFFF;
    margin: 5px;
    background-color: #3366CC;
    border-radius: 1.563rem;
    border: solid 0.1px #3366CC;
    font-size: 16px;
    line-height: 0.563rem;
    padding: 0.75rem 1rem;
    border-width: 0.125rem;
    border-style: solid;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;

  }

  input{
    font-size: 16px;
    // line-height: 0.563rem;
    // padding: 0.75rem 1rem;
    // border-width: 0.125rem;
    border-style: solid;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    padding: 0px 16px;
    height: 40px;

  }

  button:hover,
  button:focus-visible{
    background-color: #004884;
    border-color: #004884;
    color: #FFFFFF;
  }


}
.scroll {
  height: 100%;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  background: #E6EFFD;
  width: 0.7em;
  padding: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #004884 !important;
}
// Variable de colores
$title-color: #3FBEE2;
$subTitle-color: #3366cc;
// $blue-color: #0b0f4b;
// $green-color: #138468;
.body-cs .bdc-custom * {
  background: black;
  color: #ddfb00!important;
}

.body-cs .bdc-custom select {
  color: #ddfb00!important;
  background: black!important;
}

.body-cs .bdc-custom textarea {
  color: #ddfb00!important;
  background: black!important;
}

.body-cs .bdc-custom input {
  color: #ddfb00!important;
  background: black!important;
}


html, body { height: 100%; }
